.jr-dot-arrow-hover.--large {
    border-bottom: 1px solid #e6e6e6;

    &:hover {
        .jr-hover-arrow {
            left: -75px;
        }

        svg {
            padding: 0px 7px;
            width: auto;
        }
    }
}

.jr-hover-arrow.--large {
    height: 90px;
}