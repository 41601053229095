/*How to Styles*/

.how-to {
    .how-to-item {
        height: 165px;

        .placeholder {
            height: 100%;
            width: 100%;
            background-image: url('#{map-get($paths, images)}/howto-placeholder.png');
            background-position: center center;
            background-size: cover;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .play {
                height: 56px;
                width: 56px;
                align-self: center;
                cursor: pointer;
            }

            h4 {
                width: 100%;
                text-align: center;
                color: white;
            }
        }
    }
}